<template>
  <BaseModal class="base-overlay-first main-base-modal-first">
    <div class="modal">
      <img
        alt=""
        class="header__logo"
        height="20"
        src="/logos/logo.svg"
        width="102"
      />

      <div class="modal__container">
        <p class="modal__title typography__headline3">Забыли пароль?</p>

        <p class="typography__body2">
          Введите email вашего аккаунта, куда будет отправлена ссылка для создания нового пароля
        </p>

        <form
          class="modal__container"
          @submit="(event) => handleSubmit(event)"
        >
          <BaseInput2
            v-model="fields.email[0].value"
            v-bind="fields.email[1].value"
            autocomplete="email"
            class="base-input-first"
            :error="errors.email"
            name="email"
            placeholder="Email"
            type="email"
          />

          <div class="modal__buttons">
            <BaseButton2
              class="base-button-extra-big base-button-first base-button-round"
              class-loader="base-loader-second"
              :loading
              type="submit"
            >
              Отправить
            </BaseButton2>

            <BaseModalButton
              class="base-button-extra-big base-button-round base-button-second"
              :modal="SHARED_MODAL.AuthLogin"
            >
              Отмена
            </BaseModalButton>
          </div>
        </form>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
const { errors, fields, handleSubmit, loading } = useAuthPasswordRemindForm();
</script>

<style scoped>
.modal {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--desktop) {
  .modal {
    row-gap: 40px;
  }
}

.modal__title {
  color: var(--grey-1-5);
}

.modal__container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.modal__buttons {
  display: flex;
  column-gap: 16px;
}
</style>
